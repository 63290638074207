
import Vue from 'vue';
import GoogleMap from '@/components/modules/GoogleMap.vue';

export default Vue.extend({
  name: 'ExtendedDoctorCard',
  components: {GoogleMap},
  props: {
    doctorProps: {
      type: Object,
      required: true,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    openMapDoctorUuid: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      defaultUserImg: require('@/assets/images/default-user.jpg'),
      showDoctorBio: false,
      isDoctorBioLoading: false,
      doctorBio: '',
      isMapOpen: false,
    }
  },
  computed: {
    isDesktop() {
      return window.innerWidth >= 1023;
    },
    isAnotherMapOpen() {
      return this.openMapDoctorUuid !== '' && this.openMapDoctorUuid !== this.doctorProps.uuid;
    },
    slotsKeys() {
      return Object.keys(this.doctorProps?.slots);
    },
    slots() {
      const slotsArrKeys = this.slotsKeys;
      return slotsArrKeys.map((day) => {
        return {
          day: this.$dayjs(day).format('D MMMM'),
          date: this.$dayjs(day).format('YYYY-MM-DD'),
          times: this.doctorProps?.slots[day],
          timesCount: this.doctorProps?.slots[day].length,
        };
      });
    },
    docImg() {
      return this.doctorProps.avatar ?? this.defaultUserImg;
    }
  },
  methods: {
    selectDocWithScroll(date) {
      this.$emit('select-doctor', this.doctorProps, date);
    },
    toggleMap() {
      this.$emit('opened-map', this.isMapOpen ? '' : this.doctorProps.uuid);
      this.isMapOpen = !this.isMapOpen;
    },
    async toggleDoctorBio() {
      if (!this.showDoctorBio) {
        this.isDoctorBioLoading = true;
        // Function to fetch doctor bio

        const payload = {externalId: this.doctorProps.externalId};

        const bio = await this.$store.dispatch('LOAD_DOCTOR_BIO', payload);

        if (!!bio) {
          this.doctorBio = bio;
          this.showDoctorBio = true;
        } else {
          this.$eventBus.$emit('emit-error', { state: true, text: `Something went wrong while loading bio of ${this.doctorProps.name}` });
        }

        this.isDoctorBioLoading = false;
      } else {
        this.showDoctorBio = false;
      }
    }
  }
});
