const APPT_TYPE = 'appt_type';
const APPT_PATIENT_TYPE = 'appt_patient_type';
const APPT_LOCATION = 'appt_location';
const APPT_DOCTOR = 'appt_doctor';
const APPT_TIME = 'appt_time';
const APPT_EMAIL = 'appt_email';
const APPT_PATIENT_DETAILS = 'appt_patient_details';
const APPT_BOOKING = 'appt_booking';
const TTL = 10; // 10 minutes

/**
 * Manage booking data state.
 *
 **/
const BookingStorageService = {
  setItem(key, value) {
    const now = new Date();

    const item = {
      value,
      expiry: now.getTime() + (TTL * 60 * 1000) ,
    };

    localStorage.setItem(key, JSON.stringify(item));
  },

  getItem(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },

  setAppointmentType(type: any): void {
    this.setItem(APPT_TYPE, type);
  },

  getAppointmentType() {
    return this.getItem(APPT_TYPE);
  },

  setPatientType(patientType: any): void {
    this.setItem(APPT_PATIENT_TYPE, patientType);
  },

  getPatientType() {
    return this.getItem(APPT_PATIENT_TYPE);
  },

  setLocation(location: any): void {
    this.setItem(APPT_LOCATION, location);
  },

  getLocation() {
    return this.getItem(APPT_LOCATION);
  },

  setDoctor(doc: any): void {
    this.setItem(APPT_DOCTOR, doc);
  },

  getDoctor() {
    return this.getItem(APPT_DOCTOR);
  },

  setTime(time: any): void {
    this.setItem(APPT_TIME, time);
  },

  getTime() {
    return this.getItem(APPT_TIME);
  },

  setEmail(email: any): void {
    this.setItem(APPT_EMAIL, email);
  },

  getEmail() {
    return this.getItem(APPT_EMAIL);
  },

  setPatientDetails(details: any): void {
    this.setItem(APPT_PATIENT_DETAILS, details);
  },

  getPatientDetails() {
    return this.getItem(APPT_PATIENT_DETAILS);
  },

  setBooking(application: any): void {
    this.setItem(APPT_BOOKING, application);
  },

  getBooking() {
    return this.getItem(APPT_BOOKING);
  },
};

export { BookingStorageService };
