
import Vue from 'vue';
import ExtendedDoctorCard from '@/components/cards/ExtendedDoctorCard.vue';
import {mapGetters} from 'vuex';
import GoogleMap from '@/components/modules/GoogleMap.vue';
import {isMap} from 'lodash';

export default Vue.extend({
  name: 'SelectDoctor',
  components: {GoogleMap, ExtendedDoctorCard},
  props: {
    selectedAppointmentType: {
      type: String,
      required: true,
    },
    selectedPatientType: {
      type: String,
      required: true,
    },
    selectedLocation: {
      type: String,
      required: true,
    },
    patientTypes: {
      type: Array,
      required: true,
    },
    locations: {
      type: Array,
      required: true,
    },
    apptTypes: {
      type: Array,
      required: true,
    },
    doctors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      patientType: this.selectedPatientType,
      apptType: this.selectedAppointmentType,
      location: this.selectedLocation,
      showApptDetails: false,
      openMapDoctorUuid: '',
      isMapOpen: false,
    }
  },
  watch: {
    patientType: function() {
      this.$emit('set-patient-type', this.patientType);
    },
    apptType() {
      this.$emit('set-selected-type', this.apptType);
    },
    location() {
      this.$emit('set-selected-location', this.location);
    },
    selectedLocation() {
      this.location = this.selectedLocation;
    }
  },
  created() {
    this.patientType = this.selectedPatientType;
    this.apptType = this.selectedAppointmentType;
    this.location = this.selectedLocation;
  },
  computed: {
    ...mapGetters(['bookingTemplates', 'bookingLoading']),
    isDesktop() {
      return window.innerWidth >= 1023;
    },
    locationsForFilterMap() {
      return this.locations.filter(({id}) => id !== null);
    },
    locationsCount() {
      return this.bookingTemplates
          .filter((template) =>
              template.public_name === this.selectedAppointmentType
              && template.type === this.selectedPatientType
              && (this.selectedLocation !== 'all' ? (String(template.location_id) === String(this.selectedLocation)) : true )
              && (template.doctors?.length > 0)
          ).length;
    },
    selectedApptTypesObj() {
      return this.apptTypes.find(type => type.value === this.selectedAppointmentType);
    },
  },
  methods: {
    isMap,
    selectDoctor(doc, hash = null) {
      this.$emit('set-selected-doctor', doc, hash);
    },

    clickOnInsurance(paymentType: string) {
      if (paymentType === 'Insurance') {
        const message = {
          type: 'CLICK_CHIP',
          target: 'insurance',
        };
        window.parent?.postMessage(message, '*');
      }
    },
    openedMap(doctorUuid: string) {
      this.openMapDoctorUuid = doctorUuid;
    },
    toggleMap() {
      this.isMapOpen = !this.isMapOpen;
    },
  }
});
