
import Vue from 'vue';
import SelectAppointmentType from '@/components/screens/BookingWidget/SelectAppointmentType.vue';
import SelectDoctor from '@/components/screens/BookingWidget/SelectDoctor.vue';
import SelectTime from '@/components/screens/BookingWidget/SelectTime.vue';
import ConfirmTime from '@/components/screens/BookingWidget/ConfirmTime.vue';
import EnterDetails from '@/components/screens/BookingWidget/EnterDetails.vue';
import InsuranceDetails from '@/components/screens/BookingWidget/InsuranceDetails.vue';
import {BookingStorageService} from '@/services/BookingStorage.service';
import {mapGetters} from 'vuex';
import GoogleMap from '@/components/modules/GoogleMap.vue';

export default Vue.extend({
  name: 'BookingWidget',
  components: {GoogleMap, InsuranceDetails, EnterDetails, ConfirmTime, SelectTime, SelectAppointmentType, SelectDoctor},
  data() {
    return {
      currentScreen: 'new',
      screens: {
        NEW: 'new',
        APPT_DOCTOR: 'appt-doctor',
        APPT_SLOT: 'appt-slot',
        APPT_CONFIRM_TIME: 'appt-confirm-time',
        APPT_DETAILS: 'appt-details',
        APPT_INSURANCE: 'appt-insurance'
      },
      patientTypes: [
        {name: 'New Patient', value: 'new'},
        {name: 'Existing Patient', value: 'existing'},
      ],
      apptImg: [
          {name: 'Chiropractic', img: require('@/assets/images/chiropractic.png'), paymentType: 'Insurance'},
          {name: 'Occupational Therapy', img: require('@/assets/images/occupational_therapy.png'), paymentType: 'Insurance'},
          {name: 'Physical Therapy', img: require('@/assets/images/phisical_therapy.png'), paymentType: 'Insurance'},
          {name: 'Acupuncture', img: require('@/assets/images/Acupuncture.png'), paymentType: 'Insurance'},
          {name: 'Massage Therapy', img: require('@/assets/images/MassageTherapy.png'), paymentType: 'Cash'},
      ],
      apptDefaultImg: require('@/assets/images/default-treatment.png'),
      selectedTime: BookingStorageService.getTime() ?? null,
      selectedDoctor: BookingStorageService.getDoctor() ?? null,
      selectedAppointmentType: BookingStorageService.getAppointmentType() ?? null,
      selectedLocation: BookingStorageService.getLocation() ?? 'all',
      selectedPatientType: BookingStorageService.getPatientType() ?? 'new',
      patientDetails: BookingStorageService.getPatientDetails() ?? null,
      email: BookingStorageService.getEmail() ?? '',
      booking: BookingStorageService.getBooking() ?? null,
    }
  },
  beforeCreate() {
    this.$store.dispatch('LOAD_TEMPLATES');
  },
  created() {
    this.updateScreenFromParams();

  },
  watch: {
    '$route.params.screen': 'updateScreenFromParams',
  },
  mounted() {
    this.currentScreen = this.$route.params.screen.toLowerCase() ?? this.screens.NEW.toLowerCase();

    this.sendMessageToParent();

    if (this.currentScreen !== this.screens.NEW && !this.selectedAppointmentType) {
      this.navigateToStep(this.screens.NEW);
    }
  },
  computed: {
    ...mapGetters(['bookingTemplates', 'bookingLoading', 'bookingLocations']),
    locations() {
      const mappedLocations = this.bookingLocations.map((location) => {
        return {
          id: String(location.id),
          name: location.short_public_name ?? location.name,
          address: location.address,
          phoneNumber: location.phone_numbers,
          value: String(location.id),
          position: location.position ?? null,
        }
      });

      return [
        {
          id: null,
          name: 'All Locations',
          address: null,
          value: 'all',
          position: null,
        },
        ...mappedLocations,
      ];
    },
    isDesktop() {
      return window.innerWidth >= 1023;
    },
    isMapAvailable() {
      return [this.screens.NEW, this.screens.APPT_DOCTOR, this.screens.APPT_SLOT].includes(this.currentScreen)
    },
    appointmentTypes() {
      const apptTypes = new Map();

      this.bookingTemplates.forEach((template) => {
        if (apptTypes.has(template.public_name)) {
          const existing = apptTypes.get(template.public_name);
          existing.booked += template.booked;

          if (template.type === this.selectedPatientType) {
            existing.duration = `${template.duration} mins`;
          }
        } else {
          const img = this.apptImg.find(({name}) => name === template.public_name)?.img;
          const paymentType = this.apptImg.find(({name}) => name === template.public_name)?.paymentType;
          apptTypes.set(template.public_name, {
            name: template.public_name,
            img: img ?? this.apptDefaultImg,
            value: template.public_name,
            paymentType: paymentType ?? 'Insurance',
            duration: `${template.duration} mins`,
            booked: template.booked,
          });
        }
      });

      return Array.from(apptTypes.values());
    },
    selectedApptTypesObj() {
      return this.appointmentTypes.find(type => type.value === this.selectedAppointmentType);
    },
    templatesForChoose() {
      return this.bookingTemplates
          .filter((template) =>
              template.public_name === this.selectedAppointmentType
              && template.type === this.selectedPatientType
              && (this.selectedLocation !== 'all' ? (String(template.location_id) === String(this.selectedLocation)) : true ));
    },
    doctors() {
      const locations = new Map;

      this.bookingLocations.forEach(location => {
        locations.set(location.id, location);
      });

      let doctors = [];

      this.templatesForChoose.map((temp) => {
        const docs = temp.doctors.map(doc => {
          const location = locations.get(temp.location_id);
          return {
            ...doc,
            address: location?.address ?? null,
            phoneNumber: location?.phone_numbers ?? null,
            locationName: location?.short_public_name ?? location?.name ?? null,
            locationId: location?.id ?? null,
            locationPosition: location?.position ?? null,
            templateId: temp.id,
          }
        })
        doctors = [
            ...doctors,
            ...docs,
        ]
      });

      return doctors.map((doc) => {
        return {
          name: doc.data.name,
          avatar: doc.data.avatar,
          shortBio: doc.data.short_bio,
          address: doc.address,
          phoneNumber: doc.phoneNumber,
          locationName: doc.locationName,
          locationId: doc.locationId,
          locationPosition: doc.locationPosition,
          uuid: doc.data.uuid,
          slots: doc.slots,
          specialty: doc.data.specialty,
          templateId: doc.templateId,
          externalId: doc.data.externalId,
        };
      });
    }
  },
  methods: {
    updateScreenFromParams() {
      const screen = this.$route.params.screen.replace(/-/g, '_').toUpperCase();
      if (screen && this.screens[screen]) {
        this.currentScreen = this.screens[screen];
      } else {
        this.currentScreen = this.screens.CHOOSE_APPT_TYPE;
      }
    },
    navigateToStep(screen, hash = null) {
      const route = {
        name: 'booking-widget',
        params: { screen: screen },
        hash: hash ? `#${hash}` : '',
      };

      this.$router.push(route).then(() => {
        this.sendMessageToParent();
      });
    },
    sendMessageToParent() {
      const message = {
        type: 'URL_CHANGE',
        url: window.location.href,
      };
      window.parent?.postMessage(message, '*');
    },
    setPatientType: function(type) {
      this.selectedPatientType = type;
      BookingStorageService.setPatientType(type);
    },
    setSelectedLocation: function(location) {
      this.selectedLocation = location;
      BookingStorageService.setLocation(location);
    },
    setSelectedAppointmentType: function(type) {
      this.selectedAppointmentType = type;
      BookingStorageService.setAppointmentType(type);
    },
    setSelectedDoctor: function(doc, hash) {
      this.selectedDoctor = doc;
      BookingStorageService.setDoctor(doc);
      this.navigateToStep(this.screens.APPT_SLOT, hash);
    },
    setSelectedTime: function(time) {
      this.selectedTime = time;
      BookingStorageService.setTime(time);
      this.navigateToStep(this.screens.APPT_CONFIRM_TIME);
    },
    setEmail: async function (email: String) {
      this.email = email;
      BookingStorageService.setEmail(email);

      const templateId = this.selectedDoctor?.templateId;
      const doctorUUID = this.selectedDoctor?.uuid;

      const payload = {
        data: {
          template_id: templateId,
          doctor_uuid: doctorUUID,
          email: this.email,
          scheduled_at: this.selectedTime,
        }
      };

      const booking = await this.$store.dispatch('SAVE_APPOINTMENT_DETAILS', payload);

      if (booking) {
        this.booking = booking;
        BookingStorageService.setBooking(booking);
        this.navigateToStep(this.screens.APPT_DETAILS);
      } else {
        console.error('Error occurred while requesting to save details');
      }
    },
    bookAppointment: async function(patientDetails) {
      this.patientDetails = patientDetails;
      BookingStorageService.setPatientDetails(patientDetails);

      const payload = {
        data: {
          booking_id: this.booking.id,
          first_name: this.patientDetails.firstName,
          last_name: this.patientDetails.lastName,
          phone_number: this.patientDetails.phoneNumber,
          zipCode: this.patientDetails.zipCode,
          date_of_birth: this.patientDetails.dateOfBirth,
          gender: this.patientDetails.sex,
          comments: this.patientDetails.comments,
          insurance_status: this.patientDetails.insuranceStatus,
        }
      };

      const booking = await this.$store.dispatch('BOOK_APPOINTMENT', payload);

      if (booking) {
        this.booking = booking;
        BookingStorageService.setBooking(booking);
        this.selectedApptTypesObj.paymentType === 'Insurance' ?
            this.navigateToStep(this.screens.APPT_INSURANCE) :
            this.forwardToThankYouPage();
      } else {
        console.error('Error occurred while booking appointment in DrChrono');
      }

    },
    checkBenefits: function(details) {
      const payload = {
        data: {
          insurance_details: details,
          booking_id: this.booking.id,
        }
      }

      const booking = this.$store.dispatch('SAVE_INSURANCE', payload);

      if (booking) {
        this.forwardToThankYouPage();
      } else {
        console.error('Error occurred while saving insurance details');
      }
    },
    forwardToThankYouPage() {
      const message = {
        type: 'REDIRECT_TO_URL',
        url: '/thank-you-appointment',
      };
      window.parent?.postMessage(message, '*');
    }
  }
})
